import React from 'react'
import styled from 'styled-components'
import { Loader } from '@livechat/design-system'
import '@livechat/design-system/dist/design-system.css'
import { setFlex } from '../../GlobalStyled'

const LoaderWrapper = styled.div`
  height: ${(props) => props.height};
  ${setFlex()};
`
const CenterLoad = ({ height = '100%' }) => (
  <LoaderWrapper height={height}>
    <Loader data-testid="center-load-component" />
  </LoaderWrapper>
)

export default CenterLoad
