import React, { useEffect } from 'react'
import moment from 'moment'
import { Wrapper, MainWrapper, Header, ErrorBarLine } from './styledComponents'
import CenterLoad from '../CenterLoad'
import ChatMessages from '../ChatMessages'

const menuIcon = `https://image.flaticon.com/icons/png/512/17/17764.png`

const _renderSwitcher = (status, history) => {
  let result = <ErrorBarLine>Error</ErrorBarLine>
  if (status === 'none') {
    result = history.length > 0 && <ChatMessages history={history} />
  }
  if (status === 'load') {
    result = <CenterLoad />
  }
  return result
}

function _groupSameDate(dates) {
  const groups = dates.reduce((groups, mesg) => {
    const date = moment(mesg.created_at).startOf('day').format('DD-MM-YYYY')
    if (!groups[date]) {
      groups[date] = []
    }
    groups[date].push(mesg)
    return groups
  }, {})
  const groupArrays = Object.keys(groups).map((date) => ({
    date,
    messages: groups[date],
  }))
  return groupArrays
}

const History = ({ chatHistory }) => {
  // mocking response message
  // const { status } = chatHistory
  // const history = [
  //   {
  //     type: 'MESG',
  //     from: 'bot',
  //     message: 'ยินดีต้อนรับจาก chatbot',
  //     created_at: 1593666305,
  //   },
  //   {
  //     type: 'MESG',
  //     from: 'user',
  //     message: 'กอไก่ขอไข่↵คอควาย↵ขอขวด',
  //     created_at: 1593747243,
  //   },
  //   {
  //     type: 'MESG',
  //     from: 'user',
  //     message: 'สวัสดี',
  //     created_at: 1593747243,
  //   },
  //   {
  //     type: 'FILE',
  //     from: 'user',
  //     message: 'สวัสดี',
  //     file: {
  //       url: 'https://xxx.com/img.jpg',
  //       type: 'img/jpg',
  //     },
  //     created_at: 1594794086910,
  //   },
  //   {
  //     type: 'FILE',
  //     from: 'user',
  //     message: 'สวัสดี',
  //     file: {
  //       url: 'https://xxx.com/img.jpg',
  //       type: 'img/jpg',
  //     },
  //     created_at: 1597136795,
  //   },
  // ]
  const { history, status } = chatHistory
  useEffect(() => {
    const wrapperDiv = document.getElementById('history-wrapper')
    if(wrapperDiv) {
      wrapperDiv.scrollTop = wrapperDiv.scrollHeight
    }
  }, [history])
  const groupedMessages = _groupSameDate(history)
  return (
    <MainWrapper>
      <Header>
        <h1>History</h1>
        <button type="submit">
          <img src={menuIcon} alt="" />
        </button>
      </Header>
      <Wrapper id="history-wrapper">
        {_renderSwitcher(status, groupedMessages)}
      </Wrapper>
    </MainWrapper>
  )
}

export default History
