import React from 'react'
import { connect } from 'react-redux'
import Main from '.'

import { useLiveChat, config } from '../../utils/widgetHandler'
import { getChatHistoryRequest } from './actions'
import CenterLoad from '../../components/CenterLoad'

const MainConnector = ({ chatHistory, getChatHistory }) => {
  const accessToken = useLiveChat(config, getChatHistory)
  const isWeb = window.self === window.top

  return accessToken ? (
    <>
      <Main accessToken={accessToken} isWeb={isWeb} chatHistory={chatHistory} />
    </>
  ) : (
    <CenterLoad height="100vh" />
  )
}

const mapDispatchToProps = (dispatch) => ({
  getChatHistory: (chatId, token) =>
    dispatch(getChatHistoryRequest(chatId, token)),
})

const mapStateToProps = (state) => ({
  chatHistory: state.mainReducer.chat,
})

export default connect(mapStateToProps, mapDispatchToProps)(MainConnector)
