/* istanbul ignore next */
import { accountsSdk } from '@livechat/accounts-sdk'

export const _getAccessToken = async (client_id, account_url) => {
  return new Promise((resolve, reject) => {
    try {
      accountsSdk.init({
        client_id,
        onIdentityFetched: (error, data) => {
          if (data && data.access_token) {
            resolve(data.access_token)
          } else {
            window.location.href = `${account_url}?response_type=token&client_id=${client_id}&redirect_uri=${window.location.href}`
          }
        },
      })
    } catch (e) {
      reject(e)
    }
  })
}

export function isIncognito() {
  return new Promise(function detect(resolve) {
    const yes = function () {
      resolve(true)
    } // is in private mode
    const not = function () {
      resolve(false)
    } // not in private mode
    function detectChromeOpera() {
      const isChromeOpera =
        /(?=.*(opera|chrome)).*/i.test(navigator.userAgent) &&
        navigator.storage &&
        navigator.storage.estimate
      if (isChromeOpera) {
        navigator.storage.estimate().then(function (data) {
          return data.quota < 120000000 ? yes() : not()
        })
      }
      return !!isChromeOpera
    }

    function detectFirefox() {
      const isMozillaFirefox = !!navigator.userAgent.match(/firefox/i)
      if (isMozillaFirefox) {
        if (indexedDB == null) yes()
        else {
          const db = indexedDB.open('inPrivate')
          db.onsuccess = not()
          db.onerror = yes()
        }
      }
      return isMozillaFirefox
    }

    function detectSafari() {
      const isSafari = navigator.userAgent.match(/Version\/([0-9._]+).*Safari/)
      if (isSafari) {
        const testLocalStorage = function () {
          try {
            if (localStorage.length) not()
            else {
              localStorage.setItem('inPrivate', '0')
              localStorage.removeItem('inPrivate')
              not()
            }
          } catch (_) {
            navigator.cookieEnabled ? yes() : not()
          }
          return true
        }

        const version = parseInt(isSafari[1], 10)
        if (version < 11) return testLocalStorage()
        try {
          window.openDatabase(null, null, null, null)
          not()
        } catch (_) {
          yes()
        }
      }
      return !!isSafari
    }
    // the unit test does not cover here
    function detectEdgeIE10() {
      const isEdgeIE10 =
        !window.indexedDB && (window.PointerEvent || window.MSPointerEvent)
      if (isEdgeIE10) yes()
      return !!isEdgeIE10
    }

    if (detectChromeOpera()) return
    if (detectFirefox()) return
    if (detectSafari()) return
    if (detectEdgeIE10()) return

    return not()
  })
}

export default {}
