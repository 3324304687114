import styled from 'styled-components'

export const ForbiddenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 15px;
  h3 {
    font-size: 4rem;
    margin-bottom: 20px;
  }
  footer {
    margin-top: 10px;
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: grey;
    font-size: 12px;
  }
`
