import React from 'react'
import { ForbiddenWrapper } from './styledComponents'

const Forbidden = ({ accessToken, version, isPrivate = false }) => {
  const incognitoMode = isPrivate ? 'on' : 'off'
  const message = {
    off: {
      header: `Access Forbidden`,
      body: `This page is unavailable due to policy restrictions.`,
      footer: `v.${version} ${accessToken}`,
    },
    on: {
      header: `You're in private/incognito mode.`,
      body: `Chat history will only appear in normal mode.`,
      footer: `Private mode v.${version}`,
    },
  }
  return (
    <ForbiddenWrapper>
      <h3>{message[incognitoMode].header}</h3>
      <div>{message[incognitoMode].body}</div>
      <footer>{message[incognitoMode].footer}</footer>
    </ForbiddenWrapper>
  )
}

export default Forbidden
