import axios from 'axios'

export const request = (url, option) => {
  return axios({ ...option, url })
}

export const GETOption = (token) => {
  if(token) {
    return {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'livechat-authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
    }
  } else {
    return {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }
  }
}
