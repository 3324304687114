import React from 'react'
import moment from 'moment'
import validator from 'validator'
import { MessageWrapper, SenderName, DateStyled } from './styledComponents'

const _generateLink = (url, i) => (
  <div key={url + i}>
    <a href={url} target="_blank" rel="noopener noreferrer">
      {url}
    </a>{' '}
  </div>
)

const _checkIsUrl = (messages) => {
  const splitted = messages.split(' ')
  return splitted.map((word, i) =>
    validator.isURL(word) ? _generateLink(word, i) : `${word} `
  )
}

const _dateTransform = (day) =>
  moment().startOf('day').format() === moment(day).startOf('day').format()
    ? 'Today'
    : moment(day).format('llll')

const _splittingMessage = (message) => {
  const splittedLine = message.match(/[^\n]*\n?/g)
  splittedLine.pop()
  const mesgObj = splittedLine.map((line, i) => {
    const lineItem = _checkIsUrl(line)
    const isLinkAtLast = typeof lineItem[lineItem.length - 1] === 'object'
    return (
      <div key={line + i}>
        {lineItem}
        {!isLinkAtLast && <br />}
      </div>
    )
  })
  return mesgObj
}

const Message = ({ sender, message, position, date }) => (
  <div>
    {date && (
      <DateStyled data-testid="chat-message-date">
        <div className="text">{_dateTransform(date)}</div>
      </DateStyled>
    )}
    {['first', 'single'].includes(position) && (
      <SenderName data-testid="chat-message-name" sender={sender}>
        {sender}
      </SenderName>
    )}
    <MessageWrapper
      sender={sender}
      pos={position}
      data-testid="chat-message-word"
    >
      {_splittingMessage(message || '')}
    </MessageWrapper>
  </div>
)

export default Message
