import styled from 'styled-components'
import { setColor } from '../../../GlobalStyled'

const styleObj = {
  bot: {
    backgroundColor: setColor.chatBotHistory,
    color: setColor.navy,
    borderRadius: 'unset'
  },
  user: {
    backgroundColor: setColor.chatBotUser,
    color: setColor.mainWhite,
    borderRadius: 'auto'
  },
  admin: {
    backgroundColor: setColor.grey,
    color: setColor.textGrey
  }
}

export const MessageWrapper = styled.div`
  max-width: calc(100% - 21px);
  width: fit-content;
  overflow: hidden;
  overflow-wrap: break-word;
  padding: 10px 20px 17px 20px;
  margin: 4px 0;
  background-color: ${(props) => styleObj[props.sender].backgroundColor};
  color: ${(props) => styleObj[props.sender].color};
  border-radius: ${(props) => _getRadius(props.pos, props.sender)};
  margin-left: ${(props) => styleObj[props.sender].borderRadius};
  a {
    color: ${(props) =>
      props.sender === 'bot' ? setColor.navy : setColor.mainWhite};
  }
`
export const DateStyled = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  position: relative;
  margin: 12px 0;
  .text {
    width: auto;
    display: inline-block;
    z-index: 3;
    padding: 0 20px;
    background-color: ${setColor.mainWhite};
    color: ${setColor.textGrey};
    position: relative;
    margin: 0;
  }
  &::after {
    content: '';
    width: 100%;
    border-bottom: solid 1px ${setColor.grey};
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }
`

export const SenderName = styled.p`
  margin-top: 10px;
  font-size: 13px;
  color: ${setColor.textGrey};
  text-transform: capitalize;
  text-align: ${(props) => (props.sender === 'user' ? 'end' : 'start')};
`

const _getRadius = (position, sender) => {
  let radius
  switch (position) {
    case 'first': {
      radius = sender === 'bot' ? `20px 20px 20px 5px;` : `20px 20px 5px 20px;`
      break
    }
    case 'last': {
      radius = sender === 'bot' ? `5px 20px 20px 20px;` : `20px 5px 20px 20px;`
      break
    }
    case 'single': {
      radius = `20px 20px 20px 20px;`
      break
    }
    default: {
      radius = sender === 'bot' ? `5px 20px 20px 5px;` : `20px 5px 5px 20px;`
      break
    }
  }
  return radius
}
