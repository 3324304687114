import styled from 'styled-components'
import React from 'react'
import errorIcon from '../../img/error.png'
import { setColor, setFlex } from '../../GlobalStyled'

export const ErrorBar = styled.div`
  background: rgb(214 70 70);
  color: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  img {
    margin-right: 12px;
    vertical-align: middle;
  }
`
export const ErrorBarLine = (props) => (
  <ErrorBar>
    <img src={errorIcon} alt="" />
    {props.children}
  </ErrorBar>
)

export const MainWrapper = styled.div`
  ${setFlex()};
  flex-direction: column;
  align-self: center;
  justify-self: center;
  margin: center;
  width: 100vw;
  height: 100vh;
  padding: 10px;
`
export const Header = styled.div`
  ${setFlex()};
  justify-content: space-between;
  width: 100%;
  padding: 12px;
  h1 {
    color: ${setColor.navy};
    align-self: flex-start;
  }
  img {
    height: 4px;
    width: 20px;
  }
`
export const Wrapper = styled.div`
  background-color: ${setColor.mainWhite};
  border: 1px solid ${setColor.grey};
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 12px;
  overflow-y: scroll;
`
