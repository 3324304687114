const defaultState = {
  chat: {
    history: [],
    status: 'none',
    // load , fail , none
  },
}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'GET_CHAT_HISTORY_REQUEST':
      return {
        ...state,
        chat: {
          history: state.chat.history,
          status: 'load',
        },
      }
    case 'GET_CHAT_HISTORY_SUCCESS':
      return {
        ...state,
        chat: {
          history: action.payload,
          status: 'none',
        },
      }
    case 'GET_CHAT_HISTORY_FAIL':
      return {
        ...state,
        chat: {
          history: [],
          status: 'fail',
        },
      }
    default:
      return state
  }
}

export default reducer
