import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Main from './containers/Main/mainConnector'
import { isIncognito } from './utils/tools'
import Forbidden from './components/Forbidden'
import CenterLoad from './components/CenterLoad'
import pckgJson from '../package.json'

const App = () => {
  const [page, setPage] = useState(null)
  useEffect(() => {
    async function checkIsInPrivate() {
      const isPrivate = await isIncognito()
      if (isPrivate) {
        setPage(<Forbidden isPrivate={isPrivate} version={pckgJson.version} />)
      } else if (isPrivate === false) {
        setPage(<Main />)
      } else if (isPrivate === null) {
        setPage(<CenterLoad />)
      }
    }
    checkIsInPrivate()
  }, [])

  return (
    <Router>
      <Switch>
        <Route path="/">{page}</Route>
      </Switch>
    </Router>
  )
}
export default App
