import { createDetailsWidget } from '@livechat/agent-app-sdk'
import moment from 'moment'
import { _getAccessToken } from './tools'
import { getUserStatusRequest } from '../containers/Main/actions'
import clockIcon from '../img/clock.png'
import offlineIcon from '../img/status_offline.png'
import onlineIcon from '../img/status_online.png'
import unknownIcon from '../img/status_unknown.png'
import loaderIcon from '../img/loader.gif'

export const config = {
  client_id: process.env.REACT_APP_CLIENT_ID,
  server_url: '',
  account_url: process.env.REACT_APP_LIVECHAT_ENDPOINT,
}

let intervalID = null
export function _intervalManager(flag, animate, time) {
  if (flag) intervalID = setInterval(animate, time)
  else clearInterval(intervalID)
}

export function _calculateDeltaTime(time) {
  const now = moment()
  const diffMin = now.diff(time, 'minutes')
  const diffHour = now.diff(time, 'hours')
  const diffDay = now.diff(time, 'days')

  let differenceTime
  if (diffDay <= 0) {
    if (diffHour <= 0) {
      differenceTime = `${diffMin} mins`
    } else if (diffMin - diffHour * 60 === 0) {
      differenceTime = `${diffHour} hours`
    } else if (diffHour > 0 && diffMin > 0) {
      differenceTime = `${diffHour} hours ${diffMin - diffHour * 60} mins`
    }
  } else {
    differenceTime = `${diffDay} days`
  }
  return differenceTime
}

export function _widgetModifying(widget, response) {
  let text = 'Unknown'
  let img = unknownIcon
  let lastSeen = { label: `` }
  switch (response.is_online) {
    case 'loading':
      text = 'Please wait...'
      img = `${process.env.REACT_APP_FRONTEND_URL}/${loaderIcon}`
      break
    case true:
      text = 'Online'
      img = onlineIcon
      break
    case false:
      const lastSeenResponse = moment(response.last_seen_at)
      text = 'Offline'
      img = offlineIcon
      lastSeen = {
        label: `About ${_calculateDeltaTime(lastSeenResponse)} ago`,
        iconUrl: clockIcon,
      }
      break
    default:
  }
  widget.modifySection({
    title: 'Status',
    components: [
      {
        type: 'label_value',
        data: {
          label: text,
          iconUrl: img,
        },
      },
      {
        type: 'label_value',
        data: {
          ...lastSeen,
        },
      },
    ],
  })
}

export const useLiveChat = async (
  { client_id, account_url },
  getChatHistory
) => {
  const accessToken = await _getAccessToken(client_id, account_url)
  if (accessToken) {
    const widget = await createDetailsWidget()
    let tempChatId = ''
    widget.on('customer_profile', (profile) => {
      _widgetModifying(widget, { is_online: 'loading' })
      getChatHistory(profile.chat.chat_id, accessToken)
      const setWidgetContent = async () => {
        const profileTemp = profile.chat.chat_id
        const response = await getUserStatusRequest(profileTemp, accessToken)
        _widgetModifying(widget, response.data)
      }
      setWidgetContent()
      if (tempChatId !== profile.chat.chat_id) {
        tempChatId = profile.chat.chat_id
        _intervalManager(false)
        _intervalManager(true, setWidgetContent, 60000)
      }
    })
  }
  return accessToken
}
