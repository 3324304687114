import { USER_STATUS, HISTORY_API } from '../../utils/apis'
import { request, GETOption } from '../../utils/requests'

export const getUserStatusRequest = async (chatId, token) => {
  const path = `${USER_STATUS}/${chatId}`
  try {
    const response = await request(path, GETOption(token))
    return response
  } catch (e) {
    console.log('e', e)
    return e
  }
}

export const getChatHistoryRequest = (chatId, token) => async (dispatch) => {
  try {
    dispatch({ type: 'GET_CHAT_HISTORY_REQUEST' })
    // const path =
    //   'https://demo3924516.mockable.io/v1/livesupport/sendbird/history'
    const path = `${HISTORY_API}/${chatId}`
    const response = await request(path, GETOption(token))
    console.log('response', response)
    dispatch({
      type: 'GET_CHAT_HISTORY_SUCCESS',
      payload: response.data.messages,
    })
  } catch (e) {
    dispatch({ type: 'GET_CHAT_HISTORY_FAIL' })
  }
}
